import useIntervalWhen from "@rooks/use-interval-when";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { getSession } from "../functions/getSession";
import { useSession } from "../functions/useSession";
import { ConsultModalProps, renderDialog } from "./consult/ConsultModals";

interface ExpiredSessionModalProps extends ConsultModalProps {
    expires: string;
    continueFn: () => void;
}

const ExpiredSessionModal = ({ expires, continueFn }: ExpiredSessionModalProps) => {
    const [countdown, setCountdown] = useState(moment.utc(expires).diff(moment(), "seconds"));
    const isExpired = countdown <= 0;

    const counter = function () {
        setCountdown(countdown - 1);
    };
    useIntervalWhen(counter, 1000, !isExpired);

    const fn = async function () {
        if (isExpired) {
            location.reload();
        } else {
            await continueFn();
        }
        return true;
    };

    const header = isExpired ? "Your session has expired" : "Your session is about to expire";
    const complete = isExpired ? "OK" : "Continue Session";
    const children = (
        <span>
            <p className="text-sm font-normal">
                {isExpired ? "Please sign back in." : `You will be logged out in ${countdown} second${countdown > 1 ? "s" : ""}.`}
            </p>
        </span>
    );
    return renderDialog({
        isVisible: true,
        header,
        cancel: "",
        cancelFn: fn,
        complete,
        completeFn: fn,
        children,
        className: "expiring-session-modal",
        draggable: false,
        resizable: false,
        style: {},
    });
};

// Note: NextAuth.session.maxAge and sessionMaxAge should be in sync to correctly calculate the duration
const sessionMaxAge = 20 * 60;

const calculateDuration = (value?: number): number => {
    // Calculate the duration to extend the session timeout
    if (!value) {
        return 0;
    }
    if (value < sessionMaxAge) {
        return 0;
    }
    return value - sessionMaxAge;
};

export const SessionExpire = (props: { maxAge?: number }) => {
    const [session] = useSession();
    const [showModal, setShowModal] = useState(false);
    const [_expires, setExpires] = useState(session?.expires || "");

    // Handle the case where component is rendered on every expires state change (due to extending the session) and prevent timer's value from being recalculated
    const duration = calculateDuration(props.maxAge);
    const [timerEnabled, setTimerEnabled] = useState(duration > 0);
    const timer = useRef(moment().add(duration, "seconds"));

    const prevMin = moment.utc(_expires).add(-1, "minute");

    const _getSession = async function () {
        const session = await getSession();
        setExpires(session?.expires || "");
        setShowModal(false);
    };

    useEffect(() => {
        // Handle the case were session state changes due to user activity in other tabs by checking that this instance isn't the cause of extending the session
        if (session && !timerEnabled) {
            setExpires(session.expires || "");
            setShowModal(false);

            timer.current = moment().add(duration, "seconds");
            setTimerEnabled(duration > 0);
        }
    }, [session]);

    const checkExpired = async function () {
        // Render the modal 1 minute before the session expires
        const now = moment();
        if (now >= prevMin) {
            setShowModal(true);
        }
    };
    useIntervalWhen(checkExpired, 1000, !showModal && !timerEnabled);

    const checkTimer = async function () {
        // Handle the case were we're extending the session
        const now = moment();
        await _getSession();

        if (now > timer.current) {
            setTimerEnabled(false);
        }
    };
    useIntervalWhen(checkTimer, 60 * 1000, timerEnabled);

    const fn = async function () {
        await _getSession();
    };

    return <>{showModal && <ExpiredSessionModal isVisible={showModal} cancelFn={() => {}} completeFn={() => {}} expires={_expires} continueFn={fn} />}</>;
};
