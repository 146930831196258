const dayOpts: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
};
const timeOpts: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
};

const getMessageTimeAgo = (messageSentAt: string) => {
    const currentDateTime = new Date();
    const currentDay = currentDateTime.getDate();
    const messageISODate = new Date(messageSentAt);
    const messageDateTime = messageISODate.getTime();
    const ms = currentDateTime.getTime() - messageDateTime;
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(ms / (1000 * 60));
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    const messageDay = messageISODate.getDate();
    const yesterday = currentDay - 1;

    if (seconds <= 59) {
        return "Just now";
    }
    if (minutes === 1) {
        return minutes + " min ago";
    }
    if (minutes < 60) {
        return minutes + " mins ago";
    }
    if (hours === 1) {
        return hours + " hour ago";
    }
    if (hours < 24) {
        return hours + " hours ago";
    }
    if (days === 1 && messageDay === yesterday) {
        return `Yesterday ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    if (days >= 2 && days <= 6) {
        return `${messageISODate.toLocaleDateString("en-US", { weekday: "long" })} ${messageISODate.toLocaleTimeString("en-US", timeOpts)}`;
    }
    if (days > 6 && days < 365) {
        return messageISODate.toLocaleDateString("en-US", Object.assign(dayOpts, timeOpts));
    }
    return messageISODate.toLocaleDateString("en-US", Object.assign(dayOpts, timeOpts));
};

export default getMessageTimeAgo;
