import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { screenSize } from "../../utils/constants";

const Container = styled.div`
    align-items: center;
    background-color: #f8f8f8;
    color: #404040;
    min-height: 68px;
    position: fixed;
    width: 100%;
    z-index: 3;

    @media (min-width: ${screenSize.mobile}) {
        padding: 8px;
    }

    @media (min-width: ${screenSize.tablet}) {
        padding: 8px 16px;
    }

    @media (min-width: ${screenSize.laptop}) {
        padding: 8px 24px;
    }

    @media (min-width: ${screenSize.desktop}) {
        padding: 8px 40px;
    }
`;

export function HeaderBar(props: { children: any; onClick?: MouseEventHandler<HTMLDivElement> }) {
    return (
        <Container className="flex justify-content-between" onClick={props.onClick}>
            {props.children}
        </Container>
    );
}
