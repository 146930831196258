import { Consult, ConsultReasonForVisitEnum } from "../types/consult-api";

const getVisitReason = (consult: Consult) => {
    switch (consult.reasonForVisit) {
        case ConsultReasonForVisitEnum.Initial:
            return "Initial";
        case ConsultReasonForVisitEnum.FollowUp:
        case ConsultReasonForVisitEnum.AdhocFollowUp:
            return "Follow Up";
        case ConsultReasonForVisitEnum.SideEffect:
        case ConsultReasonForVisitEnum.AdhocSideEffect:
            return "Side Effect";
        case ConsultReasonForVisitEnum.PharmacyChange:
        case ConsultReasonForVisitEnum.AdhocPharmacyChange:
            return "Pharmacy Change";
        case ConsultReasonForVisitEnum.MedicationChange:
        case ConsultReasonForVisitEnum.AdhocMedicationChange:
            return "Medication Change";
        case ConsultReasonForVisitEnum.AdditionalDocumentation:
        case ConsultReasonForVisitEnum.AdhocAdditionalDocumentation:
            return "Additional Documentation";
        case ConsultReasonForVisitEnum.AdhocPharmacyCall:
            return "Pharmacy Call";
        case ConsultReasonForVisitEnum.LabResult:
            return "Lab Result";
        default:
            return "";
    }
};

export default getVisitReason;
