import { Tooltip } from "primereact/tooltip";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Annotation } from "../../types/consult-api";

export function Annotations(props: { value: Annotation[] }) {
    return (
        <>
            {props.value?.map((annotation: Annotation, index: number) => {
                const id = `annotation-${uuidv4()}`;
                return (
                    <React.Fragment key={uuidv4()}>
                        {index > 0 && <span style={{ color: "gray" }}> | </span>}
                        <Tooltip target={`#${id}`} content={annotation.description || ""} position="top" />
                        <strong id={id} style={{ color: annotation.color || "#404040" }} className="annotation">
                            {annotation.displayedText}
                        </strong>
                    </React.Fragment>
                );
            })}
        </>
    );
}
