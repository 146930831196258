import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Annotation, Reason, ReasonStatusEnum } from "../../types/consult-api";
import { Annotations } from "./Annotations";
import { ConsultModalDialogEnum } from "./ConsultModals";

export function PatientInstructions(props: {
    value: string;
    onChange: (value: string) => void;
    annotations: Annotation[];
    isEditDisabled?: boolean;
    reason?: Reason;
    type?: ConsultModalDialogEnum;
}) {
    const isRejected = props.reason?.status === ReasonStatusEnum.Rejected || !props.reason;
    const isDisabled = props.type === ConsultModalDialogEnum.CannotProvideCare && isRejected;

    return (
        <div>
            <p className="text-base font-semibold mt-6">Treatment Plan {!props.isEditDisabled && !isDisabled && <span style={{ color: "red" }}>*</span>}</p>
            {props.isEditDisabled ? (
                <div className="text-sm font-normal">
                    {props.value ? (
                        <ReactMarkdown className="markdown">{props.value}</ReactMarkdown>
                    ) : (
                        <span style={{ color: "gray" }}>No patient instructions were entered.</span>
                    )}
                </div>
            ) : (
                <InputTextarea
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    placeholder={"Add treatment plan"}
                    rows={20}
                    className="w-full"
                    style={{ fontSize: "14px" }}
                    disabled={isDisabled}
                />
            )}
            {props.isEditDisabled ? (
                <></>
            ) : (
                <>
                    {props.annotations.length > 0 && <p className="text-sm">Text Expander: {<Annotations value={props.annotations} />}</p>}
                    <p className="text-sm" style={{ color: "gray" }}>
                        Instructions are automatically sent to the patient when the consult is completed.
                    </p>
                </>
            )}
        </div>
    );
}
