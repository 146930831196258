import * as Sentry from "@sentry/browser";
import { isUndefined } from "lodash";
import { signOut } from "next-auth/client";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSession } from "../../functions/useSession";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { ClinicianApi } from "../../types/clinician-api";
import { Consult, ConsultInteractionModeEnum } from "../../types/consult-api";
import { parseConsultText } from "../../utils";
import { AcceptSyncPhoneConsultModal, AcceptSyncVideoConsultModal } from "../queue/QueueModals";
import { ConsultModalDialogEnum, ConsultModalDialogParameters, renderFooter } from "./ConsultModals";

interface NextConsultModalFooterParameters {
    hasNextConsult: boolean;
    dialogType?: ConsultModalDialogEnum;
}

const backToQueue = () => {
    recordEvent(AWSRumEventTypeEnum.NextConsultModalQueue);
    window.location.assign("/");
};

const renderNextConsultModalFooter = (params: NextConsultModalFooterParameters) => {
    const [session] = useSession();
    const sessionUser = session?.user as any;

    const [backToQueueClicked, setBackToQueueClicked] = useState(false);
    const [logOffClicked, setLogOffClicked] = useState(false);
    const [startNextConsultClicked, setStartNextConsultClicked] = useState(false);

    const [showAcceptSyncVideoConsult, setShowAcceptSyncVideoConsult] = useState(false);
    const [showAcceptSyncPhoneConsult, setShowAcceptSyncPhoneConsult] = useState(false);
    const [consult, setConsult] = useState<any>(null);
    const toast = useRef<any>(null);

    const startNextConsult = async () => {
        let guid;
        const client = new ClinicianApi();
        try {
            const res = await client.consult.consultGetNextDueConsultRetrieve();
            guid = res.data.guid;
            setConsult(res.data);

            if (res.data.interactionMode === ConsultInteractionModeEnum.VideoChat && !res.data.isScheduled) {
                setShowAcceptSyncVideoConsult(true);
                return;
            }
            if (res.data.interactionMode === ConsultInteractionModeEnum.PhoneCall && !res.data.isScheduled) {
                setShowAcceptSyncPhoneConsult(true);
                return;
            }
            if (res.data && !res.data.isScheduled) {
                await client.consult.consultAcceptUpdate(guid, res.data);
            }
        } catch (err) {
            Sentry.captureException(err);
            toast.current.show({
                severity: "error",
                sticky: true,
                summary: "Uh oh!",
                detail: (
                    <span className="text-sm">
                        This consult is no longer available.
                        <Button
                            className="p-button-sm p-button-danger return-to-queue-toast-button"
                            label="Return to Queue"
                            onClick={() => {
                                backToQueue();
                            }}
                            style={{ marginTop: "8px" }}
                        />
                    </span>
                ),
                className: "error-toast",
                style: { textAlign: "left" },
            });
            return false;
        }

        if (guid) {
            recordEvent(AWSRumEventTypeEnum.NextConsultModalStart, { consultGuid: guid });
            window.location.assign(`/consult/${guid}`);
        } else {
            backToQueue();
        }
    };

    const acceptSyncConsult = async () => {
        if (!consult) {
            return;
        }
        const client = new ClinicianApi();
        try {
            await client.consult.consultAcceptUpdate(consult.guid, consult);
            recordEvent(AWSRumEventTypeEnum.NextConsultModalStart, { consultGuid: consult.guid });
            window.location.assign(`/consult/${consult.guid}`);
        } catch {
            backToQueue();
        }
    };

    return (
        <>
            <Toast
                ref={toast}
                onClick={() => {
                    toast.current.clear();
                }}
                style={{ zIndex: 10, top: "80px", opacity: "1" }}
            />
            <Button
                onClick={() => {
                    const client = new ClinicianApi();
                    client.clinician
                        .clinicianTakeBreakCreate()
                        .catch((err) => console.error(err))
                        .finally(() => {
                            setLogOffClicked(true);
                            signOut({ callbackUrl: "/auth/signin", redirect: false });
                            if (sessionUser) {
                                recordEvent(AWSRumEventTypeEnum.ClinicianSignOut, { clinicianGuid: sessionUser.clinician.guid, consultGuid: consult?.guid });
                            }
                        });
                }}
                disabled={backToQueueClicked || logOffClicked || startNextConsultClicked}
                label={"Take a Break & Log Off"}
                loading={logOffClicked}
                className="p-button-text programs-btn-txt-off take-break-button"
                style={{ color: "#26aebc", fontSize: "14px" }}
            ></Button>
            <Button
                onClick={() => {
                    setBackToQueueClicked(true);
                    backToQueue();
                }}
                disabled={backToQueueClicked || logOffClicked || startNextConsultClicked}
                label={"Back to Queue"}
                loading={backToQueueClicked}
                className="p-button-outlined programs-btn-txt-off"
                style={{ color: "#26aebc", fontSize: "14px" }}
            ></Button>
            {params.hasNextConsult && (
                <Button
                    autoFocus
                    onClick={() => {
                        setStartNextConsultClicked(true);
                        startNextConsult();
                    }}
                    disabled={backToQueueClicked || logOffClicked || startNextConsultClicked}
                    label={"Start Next Consult"}
                    loading={startNextConsultClicked}
                    style={{ backgroundColor: "#26aebc", fontSize: "14px" }}
                ></Button>
            )}
            {showAcceptSyncVideoConsult && (
                <AcceptSyncVideoConsultModal
                    isVisible={showAcceptSyncVideoConsult}
                    cancelFn={() => {
                        setShowAcceptSyncVideoConsult(false);
                        setStartNextConsultClicked(false);
                    }}
                    completeFn={async () => {
                        setShowAcceptSyncVideoConsult(false);
                        await acceptSyncConsult();
                    }}
                />
            )}
            {showAcceptSyncPhoneConsult && (
                <AcceptSyncPhoneConsultModal
                    isVisible={showAcceptSyncPhoneConsult}
                    cancelFn={() => {
                        setShowAcceptSyncPhoneConsult(false);
                        setStartNextConsultClicked(false);
                    }}
                    completeFn={async () => {
                        setShowAcceptSyncPhoneConsult(false);
                        await acceptSyncConsult();
                    }}
                />
            )}
        </>
    );
};

export const NextConsultModal = (params: ConsultModalDialogParameters) => {
    const [hasNextConsult, setHasNextConsult] = useState(false);
    const [loadingDialogEnabled, setLoadingDialogEnabled] = useState(false);
    const [nextConsultDialogEnabled, setNextConsultDialogEnabled] = useState(false);
    const [consultData, setConsultData] = useState<Consult | null>(null);

    useEffect(() => {
        // Handle the case where the initial dialog is not shown i.e. On Hold
        const autoComplete = [ConsultModalDialogEnum.ExternalCancel, ConsultModalDialogEnum.ExternalComplete, ConsultModalDialogEnum.OnHold].includes(
            params.type || ConsultModalDialogEnum.Unknown
        );
        if (autoComplete) {
            _completeFn();
        }
    }, []);

    const getChildren = function (type: ConsultModalDialogEnum | undefined) {
        const nextConsultInfo = (
            <>
                {consultData && (
                    <>
                        <p className={"text-sm font-normal"}>Your next consult:</p>
                        <p className={"text-sm font-normal"}>
                            {consultData.program.partnerName} - {consultData.program.name} ⋅&nbsp;
                            <span style={{ textTransform: "capitalize" }}>
                                {parseConsultText(consultData.interactionMode)}&nbsp;⋅&nbsp;{parseConsultText(consultData.visitStatus)}
                            </span>
                        </p>
                    </>
                )}
            </>
        );

        switch (type) {
            case ConsultModalDialogEnum.ApproveLab:
                return (
                    <>
                        <p className={"text-sm font-normal"}>The consult has been completed using the Program's Platform and/or EMR.</p>
                        {nextConsultInfo}
                    </>
                );
            case ConsultModalDialogEnum.OnHold:
                return (
                    <>
                        <p className={"text-sm font-normal"}>Remember to come back and finish providing care.</p>
                        {nextConsultInfo}
                    </>
                );
            default:
                return <>{nextConsultInfo}</>;
        }
    };

    const getHeader = function (type: ConsultModalDialogEnum | undefined) {
        switch (type) {
            case ConsultModalDialogEnum.AdminReject:
                return {
                    loading: "Cancelling Consult...",
                    nextConsult: "Done! Consult has been cancelled.",
                };
            case ConsultModalDialogEnum.ApproveLab:
            case ConsultModalDialogEnum.Complete:
                return {
                    loading: "Completing Consult...",
                    nextConsult: "Well Done! Consult has been completed.",
                };
            case ConsultModalDialogEnum.DenyLabConsult:
                return {
                    loading: "Completing Consult...",
                    nextConsult: "Done! Lab Order has been denied.",
                };
            case ConsultModalDialogEnum.OnHold:
                return {
                    loading: "Placing the consult on hold...",
                    nextConsult: "Done! Consult is on hold.",
                };
            case ConsultModalDialogEnum.ReferOut:
                return {
                    loading: "Recommending In-Person Care...",
                    nextConsult: "Done! Consult has been recommended for in-person care.",
                };
            case ConsultModalDialogEnum.ExternalCancel:
                return {
                    loading: "",
                    nextConsult: "Sorry, consult has been canceled by partner.",
                };
            case ConsultModalDialogEnum.ExternalComplete:
                return {
                    loading: "",
                    nextConsult: "Well Done! Consult has been completed.",
                };
            case ConsultModalDialogEnum.CreateCareTeamTask:
                return {
                    loading: "Creating Care Team Task...",
                    nextConsult: "Done! Care Team Task has been created.",
                };
            default:
                return {
                    loading: "",
                    nextConsult: "",
                };
        }
    };

    const handleError = function () {
        setNextConsultDialogEnabled(false);
        setLoadingDialogEnabled(false);
    };

    const _completeFn = async function () {
        setLoadingDialogEnabled(true);
        setNextConsultDialogEnabled(true);

        try {
            const res = await params.completeFn();
            if (!isUndefined(res) && !res) {
                return handleError();
            }
        } catch {
            return handleError();
        }

        try {
            const client = new ClinicianApi();
            const res = await client.consult.consultGetNextDueConsultRetrieve();
            setHasNextConsult(!!res.data.guid);
            setConsultData(res.data);
        } catch {}

        setLoadingDialogEnabled(false);
    };

    const isCompleteDisabled = params.isCompleteDisabled || false;
    const children = getChildren(params.type);
    const header = getHeader(params.type);
    const style = params.style || { minHeight: "225px", width: "600px" };
    return (
        <>
            <Dialog
                breakpoints={{ "400px": "280px" }}
                className={params.className}
                footer={renderFooter({
                    isCompleteDisabled,
                    cancel: params.cancel,
                    cancelFn: params.cancelFn,
                    complete: params.complete,
                    completeTooltip: params.completeTooltip || "",
                    completeFn: _completeFn,
                })}
                header={params.header}
                style={style}
                onHide={params.cancelFn}
                visible={!nextConsultDialogEnabled && params.isVisible}
                draggable={false}
                resizable={false}
            >
                {!nextConsultDialogEnabled && params.children}
            </Dialog>

            <Dialog
                breakpoints={{ "400px": "280px" }}
                header={header.loading}
                style={style}
                onHide={params.cancelFn}
                visible={nextConsultDialogEnabled && loadingDialogEnabled}
                closable={false}
                draggable={false}
                resizable={false}
            >
                <div style={{ display: "flex" }}>
                    <ProgressSpinner style={{ width: "3em", height: "3em", color: "red", marginTop: "16px" }} strokeWidth="8" />
                </div>
            </Dialog>

            <Dialog
                breakpoints={{ "400px": "280px" }}
                footer={renderNextConsultModalFooter({
                    hasNextConsult,
                    dialogType: params.type,
                })}
                header={header.nextConsult}
                style={style}
                onHide={params.cancelFn}
                visible={nextConsultDialogEnabled && !loadingDialogEnabled}
                closable={false}
                draggable={false}
                resizable={false}
            >
                {children}
            </Dialog>
        </>
    );
};
