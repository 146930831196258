import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Annotation, Reason, ReasonStatusEnum } from "../../types/consult-api";
import { Annotations } from "./Annotations";
import { ConsultModalDialogEnum } from "./ConsultModals";

export function Assessment(props: {
    value: string;
    onChange: (value: string) => void;
    annotations: Annotation[];
    isEditDisabled?: boolean;
    reason?: Reason;
    type?: ConsultModalDialogEnum;
}) {
    const isRejected = props.reason?.status === ReasonStatusEnum.Rejected || !props.reason;
    const isDisabled = props.type === ConsultModalDialogEnum.CannotProvideCare && isRejected;

    return (
        <div className={"assessment"}>
            <p className="text-base font-semibold mt-6">
                Assessment {!props.isEditDisabled && !isDisabled && <span style={{ color: "red" }}>*</span>}
                <span className="font-light"> (not shared with patient)</span>
            </p>
            {props.isEditDisabled ? (
                <div className="text-sm font-normal">
                    {props.value ? <ReactMarkdown>{props.value}</ReactMarkdown> : <span style={{ color: "gray" }}>No assessment was entered.</span>}
                </div>
            ) : (
                <InputTextarea
                    id="consultNotes"
                    placeholder={"Add assessment and plan"}
                    rows={10}
                    className="w-full"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    style={{ fontSize: "14px" }}
                    disabled={isDisabled}
                />
            )}
            {props.isEditDisabled ? (
                <></>
            ) : (
                <>
                    {props.annotations.length > 0 && <p className="text-sm">Text Expander: {<Annotations value={props.annotations} />}</p>}
                    <p className="text-sm" style={{ color: "gray" }}>
                        Assessment is automatically saved when the consult is completed.
                    </p>
                </>
            )}
        </div>
    );
}
