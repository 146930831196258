import { Session } from "next-auth";
import { useSession as _useSession } from "next-auth/client";

export function useSession(): [Session | null, boolean] {
    const [session, loading] = _useSession();
    // Exclude polling session caused by Consult Dashboard
    // if (session) {
    //     console.log("tryusesession.expires", session.expires ? moment.utc(session.expires).format() : "");
    //     const sessionUser = session.user as any;
    //     recordEvent(AWSRumEventTypeEnum.ClinicianLastSeenAt, { clinicianGuid: sessionUser.clinician.guid });
    // }
    return [session, loading];
}
