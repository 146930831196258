import { ConsultVisitStatusEnum } from "../types/consult-api";

export const resolvedStatuses = [
    ConsultVisitStatusEnum.Completed,
    ConsultVisitStatusEnum.Rejected,
    ConsultVisitStatusEnum.ReferredOut,
    ConsultVisitStatusEnum.Canceled,
];

export const screenSize = {
    mobile: "300px",
    tablet: "768px",
    laptop: "1024px",
    desktop: "1440px",
};
