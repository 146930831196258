const PhoneNumberUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const PNF = require("google-libphonenumber").PhoneNumberFormat;

const formatPhoneNumber = (phone: string) => {
    try {
        const number = PhoneNumberUtil.parse(phone, "US");
        if (PhoneNumberUtil.isValidNumber(number)) {
            return PhoneNumberUtil.isValidNumberForRegion(number, "US")
                ? PhoneNumberUtil.format(number, PNF.NATIONAL)
                : PhoneNumberUtil.format(number, PNF.INTERNATIONAL);
        }
    } catch {
        //Return as is
        return phone;
    }
    return phone;
};

export default formatPhoneNumber;
