import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { screenSize } from "../../utils/constants";

const Index = styled.div`
  margin: auto;
  height: 100%;
  min-height: 100vh;

  @media (min-width: ${screenSize.mobile}) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 64px;
  }

  @media (min-width: ${screenSize.tablet}) {
    padding-left: 56px;
    padding-right: 24px;
    padding-bottom: 80px;
  }

  @media (min-width: ${screenSize.laptop}) {
    padding-left: 56px;
    padding-right: 32px;
    padding-bottom: 80px;
  }

  @media (min-width: ${screenSize.desktop}) {
    padding-left: 56px;
    padding-right: 48px;
    padding-bottom: 80px;
  }
}
`;

const NextUpQueue = styled.div`
  margin: auto;
  height: 100%;
  min-height: 100vh;

  @media (min-width: ${screenSize.mobile}) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 64px;
  }

  @media (min-width: ${screenSize.tablet}) {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 96px;
  }

  @media (min-width: ${screenSize.laptop}) {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 128px;
  }

  @media (min-width: ${screenSize.desktop}) {
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 200px;
  }
}
`;

export function BodyContainer(props: { children: any; color?: string; paddingTop?: string; onClick?: MouseEventHandler<HTMLDivElement> }) {
    return (
        <Index onClick={props.onClick} style={{ backgroundColor: props.color || "#fff", paddingTop: props.paddingTop || "0px" }}>
            {props.children}
        </Index>
    );
}

export function SingleQueueBodyContainer(props: { children: any; color?: string; paddingTop?: string; onClick?: MouseEventHandler<HTMLDivElement> }) {
    return (
        <NextUpQueue onClick={props.onClick} style={{ backgroundColor: props.color || "#fff", paddingTop: props.paddingTop || "0px" }}>
            {props.children}
        </NextUpQueue>
    );
}
