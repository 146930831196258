import { Clinician } from "../types/consult-api";
import { PatientAllergyIntolerance } from "../types/emr-api";

const mapClinicianToAllergy = (allergies: PatientAllergyIntolerance[], clinicians: Clinician[]) => {
    return allergies.map((allergy) => {
        const matchClinician = clinicians.find((clinician) => clinician.dosespotId === allergy.lastUpdatedBy?.toString());
        if (matchClinician) {
            return {
                ...allergy,
                lastUpdatedBy: `${matchClinician?.firstName} ${matchClinician?.lastName}`,
            };
        }
        return allergy;
    });
};

export default mapClinicianToAllergy;
