const calculateAge = (birthDate: string) => {
    const today = new Date();
    const value = new Date(birthDate);
    let age = today.getFullYear() - value.getFullYear();
    const month = today.getMonth() - value.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < value.getDate())) {
        age--;
    }
    return `${age}`;
};

export default calculateAge;
