import { Dialog } from "primereact/dialog";

export const DosespotModal = (props: { isOpen: boolean; url: string; toggle: () => void }) => {
    return (
        <Dialog
            header="Prescription"
            visible={props.isOpen}
            maximizable
            modal={false}
            resizable={false}
            style={{
                width: "50vw",
                minWidth: "327px",
                position: "fixed",
                right: "30px",
                boxShadow: "0px 0px 40px 5px rgba(0,0,0, 0.25)",
            }}
            onHide={props.toggle}
            className={"dosespot-modal"}
        >
            <iframe
                id="dosespot"
                name="DoseSpot"
                src={props.url}
                style={{
                    minHeight: "700px",
                    height: "auto",
                    width: "100%",
                    border: "none",
                    padding: "20px",
                    paddingTop: "0",
                }}
            />
        </Dialog>
    );
};
