import { useContext } from "react";
import { FeatureToggleContext } from "../context/FeatureToggleContext";
import { Feature } from "../types/pages/feature";

export enum FeatureNameEnum {
    CannotProvideCareModal = "cannot_provide_care_modal",
    NextConsultModal = "confirmation_modal_with_next_consult",
    FilterByAsyncConsultsOnly = "filter_by_async_consults_only",
    FilterByProgram = "filter_by_program",
    FilterByReasonForVisit = "filter_by_reason_for_visit",
    CreateChartAddendumConsult = "create_chart_addendum_consult",
    ScheduleConsultAutoEip = "schedule_consult_auto_eip",
    CreateCareTeamTask = "create_care_team_task",
}

export const useFeatureToggle = (): [(featureName: string) => boolean, (featureName: string) => boolean] => {
    // we need to read values defined in the FeatureToggleContext.
    // In this case, we'll take only the array of enabled features.
    const { features } = useContext(FeatureToggleContext);

    const isEnabled = (featureName: string): boolean => {
        const feature = features.find((feature: Feature) => feature.name === featureName);
        return feature ? feature.enabled : false;
    };

    const exists = (featureName: string): boolean => {
        const feature = features.find((feature: Feature) => feature.name === featureName);
        return !!feature;
    };

    // For consistency, We return an array of helpers,
    // so we follow the pattern defined by the useState hook.
    // It makes the code open for extensions,
    // so no need to refactor the app when a new helper is added here.
    return [isEnabled, exists];
};
