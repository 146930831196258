import moment from "moment-timezone";
import { Session } from "next-auth";
import { getSession as _getSession } from "next-auth/client";
import { addSessionAttributes, AWSRumEventTypeEnum, recordEvent } from "../public/scripts/rum";

export async function getSession(): Promise<Session | null> {
    const session = await _getSession();
    if (session) {
        console.log("getsession session.expires", session.expires ? moment.utc(session.expires).format() : "");
        const sessionUser = session.user as any;

        // Add the Clinician guid to the RUM session attributes whenever we getSession so that all RUM events are tied
        // to a specific Clinician wherever possible. This particular function will be called regularly wherever a page
        // has the SessionExpire component.
        addSessionAttributes({ "smd:clinicianGuid": sessionUser.clinician.guid });

        recordEvent(AWSRumEventTypeEnum.ClinicianLastSeenAt, { clinicianGuid: sessionUser.clinician.guid });
    }
    return session;
}
