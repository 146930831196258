import { Consult, ConsultVisitStatusEnum } from "../types/consult-api";

const isConsultInProgress = (consult: Consult) => {
    return (
        consult.visitStatus === ConsultVisitStatusEnum.InProgress ||
        consult.visitStatus === ConsultVisitStatusEnum.ExternalInProgress ||
        consult.visitStatus === ConsultVisitStatusEnum.InCall ||
        consult.visitStatus === ConsultVisitStatusEnum.ReadyToResume ||
        consult.visitStatus === ConsultVisitStatusEnum.Waiting ||
        consult.visitStatus === ConsultVisitStatusEnum.Scheduled
    );
};

export default isConsultInProgress;
